import { useState, useEffect } from 'react';
import { useApi } from './useApi';
import { SupplierAccountApplication } from 'src/interfaces/SupplierAccountApplication';
import { Supplier } from 'src/interfaces/Supplier';
import { Organization } from 'src/interfaces/Organization';
import { ApplicationOrganization } from 'src/interfaces/ApplicationOrganization';

interface UseSandozAccountApplicationsForOrganizationProps {
    supplier: Supplier | null;
    organization: Organization | ApplicationOrganization | null;
    filterStatus?: string | undefined;
}

const useSandozAccountApplicationsForOrganization = ({ supplier, organization, filterStatus = 'all' }: UseSandozAccountApplicationsForOrganizationProps) => {
    const [applications, setApplications] = useState<SupplierAccountApplication[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const api = useApi();

    useEffect(() => {
        if (!supplier || !organization) return;

        const applicationStatus = filterStatus === 'all' ? undefined : filterStatus;
        setLoading(true);

        api
            .get(`supplier/${supplier.supplier_id}/account_applications`, {
                organization_id: organization.organization_id,
                application_status: applicationStatus,
            })
            .then((response) => {
                if (response.status === 200) {
                    const data: SupplierAccountApplication[] = response.data;
                    setApplications(data);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setError(error);
                setLoading(false);
            });
    }, [supplier, organization, filterStatus]); // Re-run effect if any of these values change

    return { applications, loading, error };
};

export default useSandozAccountApplicationsForOrganization;
