import { zodResolver } from '@hookform/resolvers/zod';
import {
  IonButton,
  IonCard,
  IonCol,
  IonIcon,
  IonRow,
  IonText,
} from '@ionic/react';
import { ShipToSchema } from 'src/features/accountApplication/components/sandoz/FormManageShipTo';
import { ModalShipTo } from 'src/features/accountApplication/components/sandoz/ModalShipTo';
import { FormError } from 'src/components/shared/FormError';
import { addOutline, closeOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { DataTableHeader } from 'src/components/shared/DataTable/interfaces/DataTableHeader';
import { DataTableItemAction } from 'src/components/shared/DataTable/interfaces/DataTableItemAction';
import { DataTable } from 'src/components/shared/DataTable';
import { DataTableRow } from 'src/components/shared/DataTable/interfaces/DataTableRow';
import { SupplierAccountApplication } from 'src/interfaces/SupplierAccountApplication';
import { Supplier } from 'src/interfaces/Supplier';
import { useSelector } from 'react-redux';
import { Organization } from 'src/interfaces/Organization';
import useSandozAccountApplicationsForOrganization from 'src/hooks/useSandozAccountApplicationsForOrganization';

export interface ShipToFormData extends ShipToSchema {
  location_id: number;
  location_name: string;
}

const applicationStep3Schema = z.object({
  shipToCount: z
    .number()
    .min(1, { message: 'You must add at least 1 Ship To location' }),
});
export type ApplicationStep3Schema = z.infer<typeof applicationStep3Schema>;

export const ApplicationStep3: React.FC<{
  supplier: Supplier;
  formId: string;
  loading?: boolean;
  setIsValid: (isValid: boolean) => void;
  setIsSubmitted: (isSubmitted: boolean) => void;

  onSubmitted: (data: ShipToFormData[]) => void;
}> = ({ supplier, formId, loading, setIsValid, setIsSubmitted, onSubmitted }) => {
  const [shipToOpen, setShipToOpen] = useState(false);
  const [matchedSubmittedApplications, setMatchedSubmittedApplications] = useState<{ location_id: number, location_name: string }[]>()
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );

  const { applications } = useSandozAccountApplicationsForOrganization({ supplier, organization })
  const hasErrors = Boolean(matchedSubmittedApplications?.length)

  const {
    handleSubmit,
    control,
    setValue,
    register,
    getValues,
    watch,
    trigger,
    formState: { errors, isSubmitted, isValid },
  } = useForm<ApplicationStep3Schema>({
    resolver: zodResolver(applicationStep3Schema),
    defaultValues: {
      shipToCount: 0,
    },
  });

  useEffect(() => {
    setIsValid(isValid);
  }, [isValid]);

  useEffect(() => {
    setIsSubmitted(isSubmitted);
  }, [isSubmitted]);

  // STEP 3 (Ship To)
  const [shipToLocations, setShipToLocations] = useState<ShipToFormData[]>([]);
  const [selectedShipTo, setSelectedShipTo] = useState<ShipToFormData | null>(
    null
  );
  const isFirstRender = useRef(true);
  useEffect(() => {
    setValue('shipToCount', shipToLocations.length);
    findMatchingApplications({ locations: shipToLocations, applications })
  }, [shipToLocations]);

  const findMatchingApplications = ({ locations, applications }: { locations: ShipToFormData[], applications: SupplierAccountApplication[] }) => {
    const locationMap = new Map();

    // Populate map with locations using location_id as key
    locations.forEach(location => {
      locationMap.set(location.location_id, location.location_name);
    });

    // Find matching location_id in applications with supplier_id
    const matchingLocations = applications
      .filter(app => app.supplier_id === supplier.supplier_id) // Check supplier_id condition
      .map(app => ({
        location_id: app.location_id,
        location_name: locationMap.get(app.location_id)
      }))
      .filter(loc => loc.location_name); // Filter out undefined values

    if (matchingLocations.length > 0) {
      setMatchedSubmittedApplications(matchingLocations)
      setIsSubmitted(true)
      setIsValid(false)
    } else {
      setMatchedSubmittedApplications([])
      if (shipToLocations.length > 0) {
        trigger()
        setIsSubmitted(false)
      } else {
        if (isFirstRender.current) {
          isFirstRender.current = false;
          return;
        }
        trigger()
        setIsValid(false)
        setIsSubmitted(true)
      }
    }
  }

  const headers: DataTableHeader[] = [
    { text: 'Location Name', key: 'name' },
    { text: 'Address', key: 'address' },
    { text: 'Licensing', key: 'licensing' },
    { text: 'GCP', key: 'gcp' },
    { text: 'GLN', key: 'gln' },
    { text: 'HIN', key: 'hin' },
  ];

  const actions = (shipTo: ShipToFormData): DataTableItemAction[] => {
    if (!loading) {
      return [
        {
          label: 'Remove',
          icon: <IonIcon icon={closeOutline} />,
          color: 'danger',
          callback: (shipTo: ShipToFormData) => {
            const newShipToLocations = [...shipToLocations];
            const index = newShipToLocations.findIndex(
              (s) => s.location_id === shipTo.location_id
            );
            newShipToLocations.splice(index, 1);
            setShipToLocations(newShipToLocations);
            trigger()
          },
        },
      ];
    }
    return [];
  };

  const onClickAddShipTo = () => {
    setShipToOpen(true);
  };

  const onSubmit: (data: ApplicationStep3Schema) => void = (data) => {
    //setIsValid(false) is to show step widget in red of that form
    if (matchedSubmittedApplications && matchedSubmittedApplications.length > 0) {
      setIsValid(false)
      return
    }
    setIsValid(true)
    setIsSubmitted(true)
    onSubmitted(shipToLocations);
  };

  const getLocationNames = () => {
    return matchedSubmittedApplications!
      .map(item => item.location_name)
      .reduce((acc, curr, index, array) => {
        if (index === array.length - 1 && array.length > 1) {
          return `${acc} and ${curr}`;
        } else if (index === array.length - 2 && array.length > 2) {
          return `${acc}, ${curr}`;
        } else {
          return acc ? `${acc}, ${curr}` : curr;
        }
      }, '')
  }

  return (
    <>
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <IonRow>
          <IonCol size="12">
            <IonCard>
              <DataTable
                title="Ship To Locations"
                subtitle="You may add one or more Ship To Locations"
                headers={headers}
                actions={actions}
                search={false}
                onClickRow={(row: DataTableRow) => {
                  if (loading) {
                    console.log(
                      'attempted to click row while form was loading'
                    );
                  } else {
                    setSelectedShipTo(row.item as ShipToFormData);
                    setShipToOpen(true);
                  }
                }}
                rows={shipToLocations.map(
                  (shipTo: ShipToFormData): DataTableRow => {
                    const row: DataTableRow = {
                      item: shipTo,
                      key: shipTo.location_id,
                      columns: [
                        {
                          header: 'name',
                          content: (
                            <>
                              <IonText className="font-size-large">
                                {shipTo.location_name}
                              </IonText>
                            </>
                          ),
                        },
                        {
                          header: 'address',
                          content: (
                            <>
                              <p>
                                {shipTo.address1}
                                {shipTo.address2 && (
                                  <span>,&nbsp;{shipTo.address2}</span>
                                )}
                              </p>
                              <p>
                                {shipTo.city} {shipTo.state}, {shipTo.zip}
                              </p>
                            </>
                          ),
                        },
                        {
                          header: 'licensing',
                          content: (
                            <>
                              <p>DEA#: {shipTo.deaLicenseNum}</p>
                              <p>State#: {shipTo.stateLicenseNum}</p>
                            </>
                          ),
                        },
                        {
                          header: 'gcp',
                          content: <p>{shipTo.gcp}</p>,
                        },
                        {
                          header: 'gln',
                          content: <p>{shipTo.gln}</p>,
                        },
                        {
                          header: 'hin',
                          content: <p>{shipTo.hin}</p>,
                        },
                      ],
                    };
                    return row;
                  }
                )}
              >
                <div className="fullWidth d-flex ion-justify-content-end">
                  <input type="hidden" {...register('shipToCount')} />
                  {!hasErrors && errors.shipToCount && (
                    <FormError className="font-size-small ion-margin-end">
                      {errors.shipToCount.message}
                    </FormError>
                  )}
                  {hasErrors && (
                    <FormError className="font-size-small ion-margin-end">
                      Account application was previously submitted for {matchedSubmittedApplications && getLocationNames()}
                    </FormError>
                  )}
                  <IonButton
                    color="primary"
                    size="small"
                    onClick={onClickAddShipTo}
                    className="ion-no-margin"
                    disabled={loading}
                  >
                    Add Ship To
                    <IonIcon slot="end" icon={addOutline} />
                  </IonButton>
                </div>
              </DataTable>
            </IonCard>
          </IonCol>
        </IonRow>
      </form>

      <ModalShipTo
        shipTo={selectedShipTo || undefined}
        isOpen={shipToOpen}
        onDismiss={(shipTo?: ShipToFormData) => {
          setShipToOpen(false);
          setSelectedShipTo(null);
          if (shipTo) {
            const foundIndex = shipToLocations.findIndex(
              (stLocation) => stLocation.location_id === shipTo.location_id
            );
            if (foundIndex > -1) {
              const newShipToLocations = [...shipToLocations];
              newShipToLocations[foundIndex] = shipTo;
              setShipToLocations(newShipToLocations);
            } else {
              setShipToLocations([...shipToLocations, shipTo]);
            }
          }
        }}
      />
    </>
  );
};
