import { IonCard, IonCol, IonRow } from '@ionic/react';
import { ChipAccount } from 'src/components/buyer/ChipAccount';
import { Account } from 'src/interfaces/Account';
import { ItemShoppingCart } from 'src/interfaces/ItemShoppingCart';
import { ShoppingCart } from 'src/interfaces/ShoppingCart';
import { InputPoNumber } from './InputPoNumber';
import { TableCartItems } from './TableCartItems';
import { Supplier } from 'src/interfaces/Supplier';
import { PopoverSupplierMeta } from 'src/components/buyer/PopoverSupplierMeta';

export const CardShoppingCart: React.FC<{
  supplier: Supplier
  shoppingCart: ShoppingCart;
  account?: Account;
  refresh: boolean;
  setRefresh: (value: boolean) => void;
  onClickDeleteItem: (item: ItemShoppingCart) => void;
}> = ({ supplier, shoppingCart, account, refresh, setRefresh, onClickDeleteItem }) => {
  return (
    <IonCard className="ion-margin-horizontal flat">
      <IonRow className="ion-padding ion-justify-content-start ion-align-items-center">
        <IonCol size="12" sizeMd="4" sizeLg="3">
          <div style={{ maxWidth: '175px' }}>
            <p className="ion-no-margin font-size-default margin-right-sm margin-left-xs margin-top-sm">
              Account:
            </p>
            {account && (
              <ChipAccount
                account={account}
                style={{ marginBottom: '0', marginLeft: '0' }}
              />
            )}
          </div>
        </IonCol>
        <IonCol size="12" sizeMd="4" sizeLg="4" sizeXl="3">
          <div className="fullWidth" style={{ maxWidth: '350px' }}>
            <InputPoNumber shoppingCart={shoppingCart} />
          </div>
        </IonCol>
        <IonCol size="12" sizeMd="4" sizeLg="5" sizeXl="6" className="ion-text-start ion-text-md-end">
          <PopoverSupplierMeta supplier={supplier} />
        </IonCol>
      </IonRow>
      {account && (
        <TableCartItems
          onClickDelete={onClickDeleteItem}
          shoppingCart={shoppingCart}
          account={account}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
    </IonCard>
  );
};
