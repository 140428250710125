import { zodResolver } from '@hookform/resolvers/zod';
import { IonCol, IonList, IonRow } from '@ionic/react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormInput } from 'src/components/shared/FormInput';
import { SelectLocation340bType } from 'src/components/shared/SelectLocation340bType';
import { SelectLocationType } from 'src/components/shared/SelectLocationType';
import { Location } from 'src/interfaces/Location';
import { v } from 'src/utils/validators';
import { z } from 'zod';

const locationSchema = z
  .object({
    locationName: v.locationName(),
    locationTypeId: z
      .number({
        errorMap: () => ({ message: 'Facility Type is required' }),
      })
      .int(),
    location340bTypeId: z
      .number({
        errorMap: () => ({ message: '340B Type is required' }),
      })
      .int(),
    opaisId: z.string().max(50, { message: 'Field is too long' }),
    facilityId: z
      .string()
      .max(30, { message: 'Facility ID is too long' })
      .optional(),
    departmentId: z
      .string()
      .max(30, { message: 'Department ID is too long' })
      .optional(),
    gcp: v.gcp(),
    gln: v.gln('GLN', false),
    hin: v.hin(),
  })
  // OPAIS 340B ID is required if the 340B type is not "Not 340B"
  .refine(
    (schema) =>
      schema.location340bTypeId !== 1 && !schema.opaisId ? false : true,
    {
      message: 'OPAIS 340B ID is required',
      path: ['opaisId'],
    }
  );

export type LocationSchema = z.infer<typeof locationSchema>;

export const FormLocation: React.FC<{
  formId: string;
  location?: Location;
  onSubmit: (location: LocationSchema) => void;
  loading: boolean;
  setChanged?: (changed: boolean) => void;
  revertToggle?: boolean;
}> = ({ formId, location, onSubmit, loading, setChanged, revertToggle }) => {
  const {
    handleSubmit,
    control,
    setValue,
    register,
    watch,
    formState: { errors },
  } = useForm<LocationSchema>({
    resolver: zodResolver(locationSchema),
    defaultValues: {
      locationName: location?.name || '',
      locationTypeId: location?.location_type_id || undefined,
      location340bTypeId: location?.location_340b_type_id || undefined,
      facilityId: location?.facility_id || '',
      departmentId: location?.department_id || '',
      opaisId: location?.opais_id || '',
      gcp: location?.gcp || '',
      gln: location?.gln || '',
      hin: location?.hin || '',
    },
  });
  const locationName = watch('locationName');
  const locationTypeId = watch('locationTypeId');
  const location340bTypeId = watch('location340bTypeId');
  const facilityId = watch('facilityId');
  const departmentId = watch('departmentId');
  const opaisId = watch('opaisId');
  const gcp = watch('gcp');
  const gln = watch('gln');
  const hin = watch('hin');

  const changed =
    locationName !== location?.name ||
    locationTypeId !== location?.location_type_id ||
    location340bTypeId !== location?.location_340b_type_id ||
    facilityId !== (location?.facility_id || '') ||
    departmentId !== (location?.department_id || '') ||
    opaisId !== (location?.opais_id || '') ||
    gcp !== (location?.gcp || '') ||
    gln !== (location?.gln || '') ||
    hin !== (location?.hin || '');

  /**
   * When the location changes, populate the form with the location's data
   */
  useEffect(() => {
    if (location) {
      populateData();
    }
  }, [location]);

  /**
   * When "revertToggle" changes AND the component has already been populated with data,
   * revert the form to the original data
   */
  useEffect(() => {
    if (changed) {
      populateData();
    }
  }, [revertToggle]);

  /**
   * Tell the parent component that the data has changed
   */
  useEffect(() => {
    if (setChanged) {
      setChanged(changed);
    }
  }, [changed]);

  const populateData = () => {
    if (location) {
      setValue('locationName', location.name || '');
      setValue('locationTypeId', location.location_type_id);
      setValue('location340bTypeId', location.location_340b_type_id);
      setValue('facilityId', location.facility_id || '');
      setValue('departmentId', location.department_id || '');
      setValue('opaisId', location.opais_id || '');
      setValue('gcp', location.gcp || '');
      setValue('gln', location.gln || '');
      setValue('hin', location.hin || '');
    }
  };

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <IonList lines="none">
        <IonRow>
          <IonCol size="12">
            <FormInput
              label="Location Name"
              onIonChange={(e) => {
                setValue('locationName', e.detail.value);
              }}
              {...register('locationName')}
              errorMessage={errors.locationName?.message}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <Controller
              name="locationTypeId"
              control={control}
              render={({ field }) => {
                return (
                  <SelectLocationType
                    disabled={loading}
                    value={field.value}
                    onIonChange={(e) => {
                      setValue('locationTypeId', e.detail.value);
                    }}
                    errorMessage={errors.locationTypeId?.message}
                  />
                );
              }}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol
            size="12"
            sizeXl={
              location340bTypeId && location340bTypeId !== 1 ? '6' : undefined
            }
          >
            <Controller
              name="location340bTypeId"
              control={control}
              render={({ field }) => {
                return (
                  <SelectLocation340bType
                    disabled={loading}
                    value={field.value}
                    onIonChange={(e) => {
                      setValue('location340bTypeId', e.detail.value);
                    }}
                    errorMessage={errors.location340bTypeId?.message}
                  />
                );
              }}
            />
          </IonCol>
          <IonCol size="12" sizeXl="6">
            {location340bTypeId && location340bTypeId !== 1 ? (
              <FormInput
                label="OPAIS 340B ID"
                onIonChange={(e) => {
                  setValue('opaisId', e.detail.value as string);
                }}
                {...register('opaisId')}
                errorMessage={errors.opaisId?.message}
              />
            ) : null}
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size="12" sizeXl="6">
            <FormInput
              label="Facility ID"
              onIonChange={(e) => {
                setValue('facilityId', e.detail.value);
              }}
              {...register('facilityId')}
              errorMessage={errors.facilityId?.message}
            />
          </IonCol>
          <IonCol size="12" sizeXl="6">
            <FormInput
              label="Department ID"
              onIonChange={(e) => {
                setValue('departmentId', e.detail.value);
              }}
              {...register('departmentId')}
              errorMessage={errors.departmentId?.message}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12" sizeXl="6">
            <FormInput
              label="GLN"
              helperText="Global Locator Number"
              onIonChange={(e) => {
                setValue('gln', e.detail.value);
              }}
              {...register('gln')}
              errorMessage={errors.gln?.message}
            />
          </IonCol>
          <IonCol size="12" sizeXl="6">
            <FormInput
              label="GCP (optional)"
              helperText="Global Company Prefix"
              onIonChange={(e) => {
                setValue('gcp', e.detail.value);
              }}
              {...register('gcp')}
              errorMessage={errors.gcp?.message}
            />
          </IonCol>
          <IonCol size="12" sizeXl="6">
            <FormInput
              label="HIN (optional)"
              helperText="Hospital Identifier Number"
              onIonChange={(e) => {
                setValue('hin', e.detail.value);
              }}
              {...register('hin')}
              errorMessage={errors.hin?.message}
            />
          </IonCol>
        </IonRow>
      </IonList>
    </form>
  );
};
