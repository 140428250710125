import {
  IonCol,
  IonIcon,
  IonItem,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import { DataTable } from 'src/components/shared/DataTable';
import { DataTableHeader } from 'src/components/shared/DataTable/interfaces/DataTableHeader';
import { DataTableRow } from 'src/components/shared/DataTable/interfaces/DataTableRow';
import { Subtitle } from 'src/components/shared/Subtitle';
import { ApplicationField } from 'src/features/accountApplication/interfaces/ApplicationField';
import { useUtils } from 'src/hooks/useUtils';
import { ApplicationOrganization } from 'src/interfaces/ApplicationOrganization';
import { Supplier } from 'src/interfaces/Supplier';
import { SupplierAccountApplication } from 'src/interfaces/SupplierAccountApplication';
import {
  alertCircleOutline,
  checkmarkCircleOutline,
  helpCircleOutline,
} from 'ionicons/icons';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useSandozAccountApplicationsForOrganization from 'src/hooks/useSandozAccountApplicationsForOrganization';

export const TableAccountApplications: React.FC<{
  organization: ApplicationOrganization | null;
  onClickRow: (application: SupplierAccountApplication) => void;
}> = ({ organization, onClickRow }) => {
  const utils = useUtils();
  const supplier: Supplier | null = useSelector(
    (state: any) => state.supplier.supplier
  );

  const [filterStatus, setFilterStatus] = useState<string | undefined>(
    'pending'
  );

  const { applications, loading } = useSandozAccountApplicationsForOrganization({ supplier, organization, filterStatus })

  const headers: DataTableHeader[] = [
    { text: 'Status', key: 'status', align: 'center', width: '80px' },
    { text: 'Contact', key: 'contact' },
    { text: 'Ship To Address', key: 'shipTo' },
    { text: 'Submitted At', key: 'created' },
  ];

  const getField = (
    application: SupplierAccountApplication,
    name: string
  ): ApplicationField | null => {
    const fields = application.fields;
    let field = null;
    fields.forEach((f) => {
      if (f.name === name) {
        field = f;
      }
    });
    return field;
  };

  const StatusIcon: React.FC<{
    status: 'pending' | 'approved' | 'rejected';
  }> = ({ status }) => {
    let label = '';
    let icon = '';
    let color: string | undefined;
    switch (status) {
      case 'pending':
        label = 'Pending';
        icon = helpCircleOutline;
        color = undefined;
        break;
      case 'approved':
        label = 'Approved';
        icon = checkmarkCircleOutline;
        color = 'success';
        break;
      case 'rejected':
        label = 'Rejected';
        icon = alertCircleOutline;
        color = 'danger';
        break;
    }
    return (
      <>
        <span className="font-size-xl">
          <IonIcon icon={icon} color={color} />
        </span>
        <p className="font-size-xs">{label}</p>
      </>
    );
  };

  return (
    <DataTable
      headers={headers}
      search={false}
      onClickRow={(row: DataTableRow) => {
        const application: SupplierAccountApplication = row.item;
        onClickRow(application);
      }}
      rows={applications.map(
        (application: SupplierAccountApplication, index): DataTableRow => {
          const row: DataTableRow = {
            item: application,
            key: application.account_application_id,
            columns: [
              {
                header: 'status',
                content: <StatusIcon status={application.application_status} />,
              },
              {
                header: 'organization',
                content: (
                  <p>{getField(application, 'Organization Name')?.value}</p>
                ),
              },
              {
                header: 'contact',
                content: (
                  <>
                    <p className="font-size-xs">
                      {
                        getField(application, 'Organization Contact Name')
                          ?.value
                      }
                    </p>
                    <p className="font-size-xs">
                      {
                        getField(application, 'Organization Contact Email')
                          ?.value
                      }
                    </p>
                  </>
                ),
              },
              {
                header: 'shipTo',
                content: (
                  <>
                    <p className="font-size-xs">
                      {getField(application, 'Ship-to Street address')?.value}
                      {getField(application, 'Ship-to Suite, Unit, etc')
                        ?.value && (
                          <span>
                            ,&nbsp;
                            {
                              getField(application, 'Ship-to Suite, Unit, etc')
                                ?.value
                            }
                          </span>
                        )}
                    </p>
                    <p className="font-size-xs">
                      {getField(application, 'Ship-to City')?.value}{' '}
                      {getField(application, 'Ship-to State')?.value},{' '}
                      {getField(application, 'Ship-to Zip')?.value}
                    </p>
                  </>
                ),
              },
              {
                header: 'created',
                content: (
                  <p className="font-size-xs">
                    {utils.formatDateTime(application.created)}
                  </p>
                ),
              },
            ],
          };
          return row;
        }
      )}
      loading={loading}
    >
      <IonRow>
        <IonCol size="12" sizeMd="5" className="ion-text-left">
          <Subtitle>Organization Name</Subtitle>
          <h3 className="ion-no-margin">{organization?.name}</h3>
        </IonCol>
        <IonCol size="12" sizeMd="7">
          <IonItem className="no-padding formInput" lines="none">
            <IonSegment
              disabled={loading}
              value={filterStatus}
              onIonChange={(e: any) => setFilterStatus(e.detail.value)}
            >
              <IonSegmentButton value="all">All</IonSegmentButton>
              <IonSegmentButton value="pending">Pending</IonSegmentButton>
              <IonSegmentButton value="approved">Approved</IonSegmentButton>
              <IonSegmentButton value="rejected">Rejected</IonSegmentButton>
            </IonSegment>
          </IonItem>
        </IonCol>
      </IonRow>
    </DataTable>
  );
};
