import {
  IonButton,
  IonCol,
  IonIcon,
  IonRouterLink,
  IonRow,
  useIonAlert,
  useIonViewWillEnter,
} from '@ionic/react';
import { Breadcrumbs, Breadcrumb } from 'src/components/shared/Breadcrumbs';
import { Page } from 'src/layouts/Page';

import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { CardShoppingCart } from './CardShoppingCart';
import { CardActions } from 'src/components/shared/CardActions';
import { LoadingInline } from 'src/components/shared/LoadingInline';
import { LogoSupplier } from 'src/components/shared/LogoSupplier';
import { useApi } from 'src/hooks/useApi';
import { Account } from 'src/interfaces/Account';
import { Location } from 'src/interfaces/Location';
import { ShoppingCart } from 'src/interfaces/ShoppingCart';
import { ItemShoppingCart } from 'src/interfaces/ItemShoppingCart';
import { Supplier } from 'src/interfaces/Supplier';
import { cartOutline, chevronForwardOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCartItems, setCarts, setToast } from 'src/redux/appReducer';

interface SupplierWithCarts extends Supplier {
  shoppingCarts: ShoppingCart[];
}

export const CartPage: React.FC = () => {
  const api = useApi();
  const carts: ShoppingCart[] | null = useSelector(
    (state: any) => state.app.carts
  );
  const accounts: Account[] | null = useSelector(
    (state: any) => state.app.accounts
  );
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const allSuppliers: Supplier[] | null = useSelector(
    (state: any) => state.app.allSuppliers
  );
  const dispatch = useDispatch();
  const breadcrumbs: Array<Breadcrumb> = [
    { label: 'Home', path: '/', direction: 'back' },
    { label: 'Orders', path: '/orders', direction: 'back' },
    { label: 'Shopping Cart' },
  ];

  const [loading, setLoading] = useState(false);
  const [supplierShoppingCarts, setSupplierShoppingCarts] = useState<
    SupplierWithCarts[]
  >([]);
  const [refresh, setRefresh] = useState(false);

  const [presentAlert] = useIonAlert();

  const [selectedTab, setSelectedTab] = useState(0);
  const handleSelectTab = (e: any) => {
    setSelectedTab(e.selected);
  };

  useIonViewWillEnter(() => {
    getShoppingCarts();
  }, []);

  useEffect(() => {
    getShoppingCarts();
  }, [selectedLocation, refresh]);

  useEffect(() => {
    if (!carts || carts.length === 0) {
      setSupplierShoppingCarts([]);
    } else {
      let supplierCarts: SupplierWithCarts[] = [];
      allSuppliers?.forEach((supplier) => {
        const supplierWithCarts: SupplierWithCarts = {
          ...supplier,
          shoppingCarts: [],
        };
        supplierWithCarts.shoppingCarts = [];
        supplierCarts.push(supplierWithCarts);
      });
      supplierCarts.map((supplier) => {
        supplier.shoppingCarts = [...carts].filter(
          (cart) => cart.supplier_id === supplier.supplier_id
        );
        return supplier;
      });
      supplierCarts = supplierCarts.filter(
        (supplierCart) => supplierCart.shoppingCarts.length > 0
      );
      setSupplierShoppingCarts(supplierCarts);
    }
  }, [carts]);

  const getShoppingCarts = () => {
    if (!selectedLocation) {
      return;
    }
    setLoading(true);
    api
      .get(`location/${selectedLocation.location_id}/shopping_carts`)
      .then(({ data }) => {
        dispatch(setCarts(data));
        setLoading(false);
        getShoppingCartItems();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getShoppingCartItems = () => {
    if (!selectedLocation) {
      return;
    }
    setLoading(true);
    api
      .get(`location/${selectedLocation.location_id}/shopping_cart_items`)
      .then((response) => {
        if (response.status === 200) {
          const data: ItemShoppingCart[] = response.data;
          dispatch(setCartItems(data));
        }
        setLoading(false);
      });
  };

  const onClickDeleteItem = (item: ItemShoppingCart) => {
    presentAlert({
      header: 'Remove Item',
      message: `${item.item_description}`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Remove',
          role: 'confirm',
        },
      ],
      onDidDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          deleteItem(item);
        }
      },
    });
  };

  const deleteItem = (item: ItemShoppingCart) => {
    api
      .delete(`shopping_cart_item/${item.shopping_cart_item_id}`)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          dispatch(
            setToast({
              header: 'Successfully Removed from cart:',
              message: item.item_description || '',
            })
          );
          setRefresh(!refresh);
        }
      });
  };

  const onClickClearItems = (supplierCart: SupplierWithCarts) => {
    presentAlert({
      header: 'Clear all Items',
      message: `Clear all items for ${supplierCart.name}?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          role: 'confirm',
        },
      ],
      onDidDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          supplierCart.shoppingCarts.forEach((cart) => {
            api
              .delete(`shopping_cart/${cart.shopping_cart_id}`)
              .then((response) => {
                if (response.status === 204) {
                  // success
                  setRefresh(!refresh);
                } else {
                  dispatch(
                    setToast({
                      header: 'Error',
                      message:
                        'There was an error clearing this shopping cart. Please reload the page and try again.',
                      color: 'danger',
                      duration: 5000,
                    })
                  );
                }
              });
          });
        }
      },
    });
  };

  const onClickSubmitOrder = (supplierCart: SupplierWithCarts) => {
    setLoading(true);
    // NOTE: the only data element that's actually needed here is the "shopping_cart_id". Passing the entire object is merely convenient.
    api.post(`purchase_order`, supplierCart.shoppingCarts).then((response) => {
      if (response.status === 201) {
        setRefresh(!refresh);
        dispatch(
          setToast({
            header: 'Success!',
            message: `Successfully submitted ${supplierCart.name} order${supplierCart.shoppingCarts.length > 1 ? 's' : ''
              }`,
          })
        );
      } else {
        dispatch(
          setToast({
            header: 'Error',
            message:
              'Something went wrong. Please refresh the page and try again.',
            color: 'danger',
          })
        );
      }
      setLoading(false);
    });
  };

  const onClickSubmitAllOrders = () => {
    presentAlert({
      header: 'Submit All Orders',
      subHeader:
        'This will submit all orders for all Suppliers in your shopping cart.',
      message: `Are you sure you wish to continue?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Submit',
          role: 'confirm',
        },
      ],
      onDidDismiss: (e: any) => {
        if (e.detail.role === 'confirm') {
          let shoppingCarts: ShoppingCart[] = [];
          supplierShoppingCarts.forEach((supplierCart: SupplierWithCarts) => {
            supplierCart.shoppingCarts.forEach((shoppingCart: ShoppingCart) => {
              shoppingCarts.push(shoppingCart);
            });
          });
          if (shoppingCarts.length === 0) {
            console.log('nothing to submit');
            return;
          }
          setLoading(true);
          api.post(`purchase_order`, shoppingCarts).then((response) => {
            if (response.status === 201) {
              setRefresh(!refresh);
              dispatch(
                setToast({
                  header: 'Success!',
                  message: 'Successfully submitted all orders',
                })
              );
            } else {
              dispatch(
                setToast({
                  header: 'Error',
                  message:
                    'Something went wrong. Please refresh the page and try again.',
                  color: 'danger',
                })
              );
            }
            setLoading(false);
          });
        }
      },
    });
  };

  // I'm thinking this could send an array of shopping_cart_id's to the backend, which will join the necessary stuff, and return a price total
  // location/{location_id}/stats/shopping_carts_total or something like that
  // const calculateSupplierTotal = (supplierCart: SupplierWithCarts): number => {
  //   let total = 0;
  //   supplierCart.shoppingCarts.forEach((cart) => {
  //     console.log(cart);
  //   });
  //   return total;
  // };

  return (
    <Page className="shoppingCart" title="Shopping Cart">
      <Breadcrumbs breadcrumbs={breadcrumbs}>
        <IonButton
          size="small"
          disabled={supplierShoppingCarts.length === 0}
          onClick={onClickSubmitAllOrders}
        >
          Submit All Orders
        </IonButton>
      </Breadcrumbs>

      <IonRow>
        <IonCol>
          {loading ? (
            <LoadingInline />
          ) : (
            <>
              {supplierShoppingCarts.length > 0 ? (
                <TabStrip
                  selected={selectedTab}
                  onSelect={handleSelectTab}
                  animation={false}
                >
                  {supplierShoppingCarts.map((supplierCart) => {
                    return (
                      <TabStripTab
                        contentClassName="ion-padding"
                        key={supplierCart.supplier_id}
                        title={
                          <LogoSupplier
                            id={supplierCart.supplier_id}
                            style={{ width: '125px', height: '35px' }}
                          />
                        }
                      >
                        {supplierCart.shoppingCarts.map((shoppingCart) => (
                          <div
                            className="ion-padding-horizontal ion-padding-top"
                            key={shoppingCart.shopping_cart_id}
                          >
                            <CardShoppingCart
                              supplier={supplierCart}
                              shoppingCart={shoppingCart}
                              account={accounts?.find(
                                (account) =>
                                  account.account_id === shoppingCart.account_id
                              )}
                              refresh={refresh}
                              setRefresh={setRefresh}
                              onClickDeleteItem={onClickDeleteItem}
                            />
                          </div>
                        ))}
                        {/* TODO: estimated supplier total */}
                        {/* <IonRow>
                          <IonCol
                            size="12"
                            sizeLg="5"
                            offsetLg="7"
                            className="ion-padding-end"
                          >
                            <IonList>
                              <IonItem>
                                <IonLabel className="ion-no-margin font-size-default">
                                  {supplierCart.name} Estimated Total
                                </IonLabel>
                                <p className="ion-no-margin font-size-default text-color-black font-weight-500">
                                  {toUsd(calculateSupplierTotal(supplierCart))}
                                </p>
                              </IonItem>
                            </IonList>
                          </IonCol>
                        </IonRow> */}
                        <CardActions className="ion-margin-top">
                          <IonCol
                            size="12"
                            sizeLg="6"
                            className="ion-no-padding ion-text-right"
                          >
                            <IonButton
                              color="danger"
                              onClick={() => onClickClearItems(supplierCart)}
                              fill="outline"
                            >
                              Clear All
                            </IonButton>
                            <IonButton
                              className="ion-margin-start"
                              onClick={() => onClickSubmitOrder(supplierCart)}
                            >
                              Submit {supplierCart.name} Order
                              {supplierCart.shoppingCarts.length > 1
                                ? 's'
                                : null}
                            </IonButton>
                          </IonCol>
                        </CardActions>
                      </TabStripTab>
                    );
                  })}
                </TabStrip>
              ) : (
                <div className="d-flex flex-column ion-justify-content-center ion-align-items-center">
                  <IonIcon
                    icon={cartOutline}
                    style={{ fontSize: '70px' }}
                    color="warning"
                  />
                  <h1>Your shopping cart is empty</h1>
                  <p>
                    Visit the{' '}
                    <IonRouterLink
                      routerLink="/items"
                      routerDirection="forward"
                    >
                      Items
                    </IonRouterLink>{' '}
                    page or select a{' '}
                    <IonRouterLink
                      routerLink="/suppliers"
                      routerDirection="forward"
                    >
                      Supplier
                    </IonRouterLink>{' '}
                    to get started.
                  </p>
                  <IonButton routerLink="/orders" routerDirection="forward">
                    Order History
                    <IonIcon icon={chevronForwardOutline} slot="end" />
                  </IonButton>
                </div>
              )}
            </>
          )}
        </IonCol>
      </IonRow>
    </Page>
  );
};
