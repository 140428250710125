import {
  IonChip,
  IonCol,
  IonIcon,
  IonRow,
  IonSpinner,
  IonText,
  useIonRouter,
} from '@ionic/react';
import { ChipAccount } from 'src/components/buyer/ChipAccount';
import { DataTable } from 'src/components/shared/DataTable';
import { DataTableHeader } from 'src/components/shared/DataTable/interfaces/DataTableHeader';
import { DataTableRow } from 'src/components/shared/DataTable/interfaces/DataTableRow';
import { ItemDescriptionCol } from 'src/components/shared/ItemDescriptionCol';
import { useApi } from 'src/hooks/useApi';
import { useUtils } from 'src/hooks/useUtils';
import { Account } from 'src/interfaces/Account';
import { EdiAck } from 'src/interfaces/EdiAck';
import { EdiInvoice } from 'src/interfaces/EdiInvoice';
import { EdiShipNotice } from 'src/interfaces/EdiShipNotice';
import { ItemPurchaseOrder } from 'src/interfaces/ItemPurchaseOrder';
import { PurchaseOrder } from 'src/interfaces/PurchaseOrder';
import { useEffect, useState } from 'react';
import { getTracking } from 'ts-tracking-number';
import { openOutline } from 'ionicons/icons';

interface ItemPurchaseOrderWithEdi extends ItemPurchaseOrder {
  ackItems: EdiAck[];
  shipNoticeItems: EdiShipNotice[];
  invoiceItems: EdiInvoice[];
}

export const Dash: React.FC<{}> = () => <span>{"-"}</span>

export const TablePurchaseOrderItems: React.FC<{
  poLoading?: boolean;
  purchaseOrder?: PurchaseOrder;
}> = ({ poLoading, purchaseOrder }) => {
  const api = useApi();
  const { toUsd } = useUtils();
  const router = useIonRouter();

  const [loading, setLoading] = useState(false);

  const [account, setAccount] = useState<Account | null>(null);
  const [accountLoading, setAccountLoading] = useState(false);

  const [purchaseOrderItems, setPurchaseOrderItems] = useState<
    ItemPurchaseOrder[]
  >([]);
  const [poItemsWithEdi, setPoItemsWithEdi] = useState<
    ItemPurchaseOrderWithEdi[]
  >([]);

  const [ackItems, setAckItems] = useState<EdiAck[]>([]);
  const [ackItemsLoading, setAckItemsLoading] = useState(false);

  const [shipNoticeItems, setShipNoticeItems] = useState<EdiShipNotice[]>([]);
  const [shipNoticeItemsLoading, setShipNoticeItemsLoading] = useState(false);

  const [invoiceItems, setInvoiceItems] = useState<EdiInvoice[]>([]);
  const [invoiceItemsLoading, setInvoiceItemsLoading] = useState(false);

  const [tableHeaders, setTableHeaders] = useState<DataTableHeader[]>([]);

  const [totalCost, setTotalCost] = useState<number>(0);

  const [trackingNumbers, setTrackingNumbers] = useState<string[]>([]);

  useEffect(() => {
    if (purchaseOrder) {
      getPurchaseOrderItems();
      getAckItems();
      getShipNoticeItems();
      getInvoiceItems();
      getAccount();
    } else {
      setAccount(null);
    }

    const headers: DataTableHeader[] = [
      { text: 'Item Description', key: 'item' },
      { text: 'Requested Quantity', key: 'poQuantity', align: 'center' },
      {
        text: 'Acknowledged Quantity',
        key: 'acknowledgedQuantity',
        align: 'center',
      },
      { text: 'Shipped Quantity', key: 'shippedQuantity', align: 'center' },
      { text: 'Invoiced Quantity', key: 'invoicedQuantity', align: 'center' },
      { text: 'Invoice Number ', key: 'invoiceNumber', align: 'center' },
    ];
    let costHeader: DataTableHeader = {
      text: '',
      key: 'totalPrice',
      align: 'right',
    };
    if (purchaseOrder && purchaseOrder.status === 'Invoiced') {
      costHeader.text = 'Invoiced Cost';
    } else {
      costHeader.text = 'Estimated Cost';
    }
    headers.push(costHeader);
    setTableHeaders(headers);
  }, [purchaseOrder]);

  useEffect(() => {
    if (shipNoticeItems.length === 0) {
      setTrackingNumbers([]);
      return;
    } else {
      let trackingNumbers: string[] = [];
      shipNoticeItems.forEach((item) => {
        if (item.tracking_number) {
          if (!trackingNumbers.includes(item.tracking_number)) {
            trackingNumbers.push(item.tracking_number);
          }
        }
      });
      setTrackingNumbers(trackingNumbers);
    }
  }, [shipNoticeItems]);

  useEffect(() => {
    if (!purchaseOrderItems || purchaseOrderItems.length === 0) {
      return;
    }
    let cost = 0;
    if (purchaseOrder?.status === 'Invoiced' && invoiceItems.length > 0) {
      invoiceItems.forEach((item: EdiInvoice) => {
        cost += parseFloat(`${item.item_unit_price}`) * item.item_quantity;
      });
    } else {
      purchaseOrderItems.forEach((item: ItemPurchaseOrder) => {
        cost += parseFloat(item.item_unit_price) * item.quantity;
      });
    }
    setTotalCost(cost);
  }, [purchaseOrderItems, invoiceItems]);

  useEffect(() => {
    if (purchaseOrderItems.length === 0) {
      return;
    }
    const poItemsWithEdi = purchaseOrderItems.map(
      (poItem: ItemPurchaseOrder): ItemPurchaseOrderWithEdi => {
        return {
          ...poItem,
          ackItems: findAckItems(poItem),
          invoiceItems: findInvoiceItems(poItem),
          shipNoticeItems: findShipNoticeItems(poItem),
        };
      }
    );
    setPoItemsWithEdi(poItemsWithEdi);
  }, [ackItems, invoiceItems, shipNoticeItems, purchaseOrderItems]);

  const findAckItems = (item: ItemPurchaseOrder): EdiAck[] => {
    return ackItems.filter(
      (ackItem) => ackItem.purchase_order_item_ndc === item.item_ndc
    );
  };
  const findInvoiceItems = (item: ItemPurchaseOrder): EdiInvoice[] => {
    return invoiceItems.filter(
      (invoiceItem) => invoiceItem.item_ndc === item.item_ndc
    );
  };
  const findShipNoticeItems = (item: ItemPurchaseOrder): EdiShipNotice[] => {
    return shipNoticeItems.filter(
      (shipNoticeItem) => shipNoticeItem.item_ndc === item.item_ndc
    );
  };

  const getAccount = () => {
    if (!purchaseOrder) {
      return;
    }
    setAccountLoading(true);
    api
      .get(`account/${purchaseOrder.account_id}`)
      .then((response) => {
        if (response.status === 200) {
          const data: Account = response.data;
          setAccount(data);
          setAccountLoading(false);
        }
        setAccountLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setAccountLoading(false);
      });
  };

  const getPurchaseOrderItems = () => {
    if (!purchaseOrder) {
      return;
    }
    setLoading(true);
    api
      .get(
        `purchase_order/${purchaseOrder.purchase_order_id}/purchase_order_items`
      )
      .then(({ data }) => {
        setPurchaseOrderItems(data || []);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const getAckItems = () => {
    if (!purchaseOrder) {
      return;
    }
    setAckItemsLoading(true);
    api
      .get(`purchase_order/${purchaseOrder.purchase_order_id}/ack`)
      .then((response) => {
        if (response.status === 200) {
          setAckItems(response.data || []);
        }
        setAckItemsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setAckItemsLoading(false);
      });
  };

  const getShipNoticeItems = () => {
    if (!purchaseOrder) {
      return;
    }
    setShipNoticeItemsLoading(true);
    api
      .get(`purchase_order/${purchaseOrder.purchase_order_id}/ship_notice`)
      .then((response) => {
        if (response.status === 200) {
          setShipNoticeItems(response.data || []);
        }
        setShipNoticeItemsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setShipNoticeItemsLoading(false);
      });
  };

  const getInvoiceItems = () => {
    if (!purchaseOrder) {
      return;
    }
    setInvoiceItemsLoading(true);
    api
      .get(`purchase_order/${purchaseOrder.purchase_order_id}/invoice`)
      .then((response) => {
        if (response.status === 200) {
          setInvoiceItems(response.data || []);
        }
        setInvoiceItemsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setInvoiceItemsLoading(false);
      });
  };

  const getItemPrice = (item: ItemPurchaseOrderWithEdi) => {
    let price = 0;
    if (purchaseOrder?.status === 'Invoiced') {
      item.invoiceItems.forEach((invoiceItem) => {
        price +=
          parseFloat(`${invoiceItem.item_unit_price}`) *
          invoiceItem.item_quantity;
      });
    } else {
      price += parseFloat(`${item.item_unit_price}`) * item.quantity;
    }
    return toUsd(price);
  };

  const onClickTrackingNumber = (trackingNumber: string) => {
    const tracking = getTracking(trackingNumber);
    let trackingUrl = tracking?.trackingUrl!.replace(
      '%s',
      tracking?.trackingNumber
    );
    if (trackingUrl) {
      window.open(trackingUrl, '_blank');
    }
  };

  const AckQuantity: React.FC<{ item: ItemPurchaseOrderWithEdi }> = ({
    item,
  }) => {
    if (ackItemsLoading) {
      return <IonSpinner name="crescent" color="primary" />;
    }
    if(item.ackItems.length === 0) return <Dash />;
    let quantity = 0;
    item.ackItems.forEach((ackItem) => {
      quantity += ackItem.item_quantity;
    });
    return <p>{quantity}</p>;
  };

  const InvoiceQuantity: React.FC<{ item: ItemPurchaseOrderWithEdi }> = ({
    item,
  }) => {
    if (invoiceItemsLoading) {
      return <IonSpinner name="crescent" color="primary" />;
    }
    if(item.invoiceItems.length === 0) return <Dash />;
    let quantity = 0;
    item.invoiceItems.forEach((invoiceItem) => {
      quantity += invoiceItem.item_quantity;
    });
    return <p>{quantity}</p>;
  };

  const InvoiceNumbers: React.FC<{ item: ItemPurchaseOrderWithEdi }> = ({
    item,
  }) => {
    if (invoiceItemsLoading) {
      return <IonSpinner name="crescent" color="primary" />;
    }
    if(item.invoiceItems.length === 0) return <Dash />;
    return <div>
      {item.invoiceItems.map((invoiceItem, index) => (
        <span key={`invoice-${index}`}>
          {invoiceItem.invoice_number}
        </span>
      ))}
  </div>;
  };

  const ShippedQuantity: React.FC<{ item: ItemPurchaseOrderWithEdi }> = ({
    item,
  }) => {
    if (shipNoticeItemsLoading) {
      return <IonSpinner name="crescent" color="primary" />;
    }
    if(item.shipNoticeItems.length === 0) return <Dash />;
    let quantity = 0;
    item.shipNoticeItems.forEach((shippedItem) => {
      quantity += Number(shippedItem.item_quantity);
    });
    return <p>{quantity}</p>;
  };



  return (
    <>
      <DataTable
        headers={tableHeaders}
        loading={loading || poLoading}
        onClickRow={(row: DataTableRow) => {
          const item = row.item as ItemPurchaseOrderWithEdi;
          console.log(item);
        }}
        rows={poItemsWithEdi.map((item): DataTableRow => {
          const row: DataTableRow = {
            item: item,
            key: `${item.supplier_item_number}-${item.account_number}`,
            columns: [
              {
                header: 'item',
                content: <ItemDescriptionCol item={item} clickable={false} />,
              },
              {
                header: 'poQuantity',
                content: <p>{item.quantity}</p>,
              },
              {
                header: 'acknowledgedQuantity',
                content: <AckQuantity item={item} />,
              },
              {
                header: 'shippedQuantity',
                content: <ShippedQuantity item={item} />,
              },
              {
                header: 'invoicedQuantity',
                content: <InvoiceQuantity item={item} />,
              },
              {
                header: 'invoiceNumber',
                content: <InvoiceNumbers item={item} />,
              },
              {
                header: 'totalPrice',
                content: (
                  <p className="font-weight-500 text-color-black">
                    {getItemPrice(item)}
                  </p>
                ),
              },
            ],
          };
          return row;
        })}
      >
        <div className="fullWidth d-flex ion-align-items-start">
          <div className="ion-text-left margin-right-sm">
            {account ? (
              <>
                <p
                  className="ion-no-margin font-size-default margin-right-sm margin-left-xs"
                  style={{ marginLeft: 'var(--app-spacing-xs)' }}
                >
                  Account:
                </p>
                <ChipAccount
                  loading={accountLoading}
                  account={account}
                  style={{ marginLeft: 0, marginBottom: 0 }}
                />
              </>
            ) : null}
          </div>
          {trackingNumbers.length > 0 ? (
            <div className="ion-text-left margin-left-sm">
              <p
                className="ion-no-margin font-size-default margin-right-sm margin-left-xs"
                style={{ marginLeft: 'var(--app-spacing-xs)' }}
              >
                Tracking:
              </p>
              {trackingNumbers.map((trackingNumber, index) => {
                return (
                  <IonChip
                    key={trackingNumber}
                    onClick={() => onClickTrackingNumber(trackingNumber)}
                    className="ion-no-margin margin-right-xs margin-bottom-xs margin-top-xs"
                    data-tooltip-id="global-tooltip"
                    data-tooltip-place="bottom"
                    data-tooltip-html={`
                      <div>
                      Go to ${
                        getTracking(trackingNumber)?.courier.name
                      } tracking
                      </div>
                    `}
                  >
                    <IonText
                      color="secondary"
                      className="d-inline-flex ion-align-items-center"
                    >
                      <span
                        className="font-size-xs"
                        style={{ textDecoration: 'underline' }}
                      >
                        {trackingNumber}
                      </span>
                      <IonIcon icon={openOutline} className="margin-left-xs" />
                    </IonText>
                  </IonChip>
                );
              })}
            </div>
          ) : null}
        </div>
      </DataTable>

      <IonRow>
        <IonCol className="ion-text-right ion-padding">
          <h2 className="font-size-large">
            {purchaseOrder?.status === 'Invoiced' ? 'Invoiced' : 'Estimated'}{' '}
            Cost:{' '}
            <strong className="text-color-black">{toUsd(totalCost)}</strong>
          </h2>
        </IonCol>
      </IonRow>
    </>
  );
};
